import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';

// Declare JQuery
declare var jQuery: any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('true' , style({ opacity: 1, height: '*' })),
      state('false', style({ opacity: 0, overflow: 'hidden', height: '0px', display: 'none', margin: '0 auto', top: '0', bottom: '0' })),
      transition('1 => 0', animate('200ms')),
      transition('0 => 1', animate('200ms'))
    ])
  ],
  host: {
      '(window:resize)': 'onResize($event)'
    }
})
export class FooterComponent implements OnInit {

  public today: number;
  public showWebsitePlanModal: boolean = false;

  @Input() isVisible1: boolean;
  @Input() isVisible2: boolean;
  @Input() isVisible3: boolean;
  @Input() isVisible4: boolean;

  constructor() { }

  ngOnInit() {
    this.today = Date.now();
    this.setAllVisibleToTrueOrFalse(jQuery(window).width() <= 480 ? false : true);
    this.scrollToTop();
  }

  /**
  *
  */
  public showOrHide(elementToHide: string): void {
    if(jQuery(window).width() <= 480) {
      if(elementToHide == '1') {
        this.isVisible1 = this.isVisible1 ? false : true;
      } else if(elementToHide == '2') {
        this.isVisible2 = this.isVisible2 ? false : true;
      } else if(elementToHide == '3') {
        this.isVisible3 = this.isVisible3 ? false : true;
      } else if(elementToHide == '4') {
        this.isVisible4 = this.isVisible4 ? false : true;
      }
    } else {
      this.setAllVisibleToTrueOrFalse(true);
    }
  }

  /**
  * Event called when screen size change
  */
  onResize(event): void {
    // event.target.innerWidth; // window width
    if(jQuery(window).width() <= 480) {
      this.setAllVisibleToTrueOrFalse(false);
    } else {
     this.setAllVisibleToTrueOrFalse(true);
    }
  }

  public setAllVisibleToTrueOrFalse(value: boolean): void {
    if(value) {
      this.isVisible1 = true;
      this.isVisible2 = true;
      this.isVisible3 = true;
      this.isVisible4 = true;
    } else {
      this.isVisible1 = false;
      this.isVisible2 = false;
      this.isVisible3 = false;
      this.isVisible4 = false;
    }
  }

  /**
  * Scroll to the top of the page
  */
  public scrollToTop(): void {
    jQuery(function() {
    	jQuery(document).on('scroll', function() {
    		if (jQuery(window).scrollTop() > 100) {
    			jQuery('.scroll-to-top').addClass('show');
    		} else {
    			jQuery('.scroll-to-top').removeClass('show');
    		}
    	});

      // Adding scroll animation
    	jQuery('.scroll-to-top').on('click', jQuery('html, body').animate({ scrollTop : jQuery('body').offset().top }, 500, 'linear'));
    });
  }

}
