// Angular modules
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {LocationStrategy, PathLocationStrategy} from '@angular/common'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

// PrimeNg modules
import {RippleModule} from 'primeng/ripple';
import {DeferModule} from 'primeng/defer';
import {CaptchaModule} from 'primeng/captcha';
import {DialogModule} from 'primeng/dialog';

// Bootstrap module
import {AppBootstrapModule} from './app-bootstrap.module';

// Router module
import {AppRoutingModule} from './app-routing.module';

// My components
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {AccueilComponent} from './accueil/accueil.component';
import {ServicesComponent} from './services/services.component';
import {PortfolioComponent} from './portfolio/portfolio.component';
import {ContactComponent} from './contact/contact.component';
import {ClientComponent} from './client/client.component';

// My Services
import {SendEmailService} from './send-email.service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AccueilComponent,
    ServicesComponent,
    PortfolioComponent,
    ContactComponent,
    ClientComponent
  ],
  imports: [
    // Angular modules
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    // Router module
    AppRoutingModule,

    // Bootstrap module
    AppBootstrapModule,

    // PrimeNg modules
    RippleModule,
    DeferModule,
    CaptchaModule,
    DialogModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy}, SendEmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
