<section id="portfolio" class="container">

  <div class="container">
    <!-- Start Page Title -->
    <div class="page-title">
      <h1 class="text-left">Portfolio</h1>
      <div class="bolded-line"></div>
    </div>
    <!-- End Page Title -->

    <div class="BackgroundColorLINO">Quelques réalisations</div>
  </div>


    <div class="container">
      <!-- Start Portfolio Filters -->
      <div class="addMargin">
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 0}" (click)="onFilterClick(0)" data-filter="all">Tous</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 1}" (click)="onFilterClick(1)" data-filter="affiche">Affiche</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 2}" (click)="onFilterClick(2)" data-filter="autoCopiant">Auto-copiant</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 3}" (click)="onFilterClick(3)" data-filter="calendrier">Calendrier</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 4}" (click)="onFilterClick(4)" data-filter="carteDeVisite">Carte de Visite</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 5}" (click)="onFilterClick(5)" data-filter="carteDeVoeux">Carte de Voeux</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 6}" (click)="onFilterClick(6)" data-filter="chemise">Chemise</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 7}" (click)="onFilterClick(7)" data-filter="depliant">Dépliant</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 8}" (click)="onFilterClick(8)" data-filter="flyer">Flyer</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 9}" (click)="onFilterClick(9)" data-filter="journaux">Journaux</button>
        <button pRipple type="button" class="btn btn-default filter-button" [ngClass]="{'btn-primary': filterIndex === 10}" (click)="onFilterClick(10)" data-filter="revue">Revue</button>
      </div>
    </div>
    <!-- End Portfolio Filters -->



    <!-- Start Portfolio Images -->
    <div class="row">
      <div class="col-lg-4 col-sm-6 col-xs-12" *ngFor="let portfolioImage of portfolioImages">
        <div pDefer>
          <ng-template>
            <div class="addMargin filter all {{portfolioImage.filterKey}}">
              <div class="thumbnail">
                <a href="{{portfolioImage.urlLarge}}" data-lightbox="portfolio" data-title="{{portfolioImage.description}}">
                  <img class="img-responsive" src="{{portfolioImage.urlSmall}}" alt="{{portfolioImage.description}}" loading="lazy">
                  <span class="rollover"></span>
                </a>
<!--                <div class="caption">-->
<!--                  <h3>{{portfolioImage.description}}</h3>-->
<!--                </div>-->
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <!-- End Portfolio Images -->
    </div>


  <!-- Start of Client component -->
  <app-client></app-client>
  <!-- Start of Client component -->


</section>
