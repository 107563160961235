<section id="contact">

  <div class="container">
    <!-- Start Page Title -->
    <div class="page-title">
      <h1 class="text-left">Contact</h1>
      <div class="bolded-line"></div>
    </div>
    <!-- End Page Title -->

    <div class="BackgroundColorLINO">Contactez-nous</div>
  </div>


  <div class="container-fluid lead text-center text-muted addMargin addPadding">
    <p>Vous pouvez nous contacter directement en nous écrivant via le formulaire ci-dessous.</p>
    <p>Nous vous contacterons dans les plus brefs délais.</p>
  </div>


  <div>
    <!-- Start of Contact Form-->
    <form id="contactForm" name="contactForm" class="container-fluid" #sendEmailForm="ngForm" (submit)="sendEmail(sendEmailForm.value)">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input id="nomEtPrenom" name="nomEtPrenom" class="form-control" type="text" placeholder="Votre Nom et Prénom *" [(ngModel)]="message.nomEtPrenom" required #nomEtPrenom="ngModel" minlength="2">
            <div *ngIf="nomEtPrenom.invalid && (nomEtPrenom.dirty || nomEtPrenom.touched)" class="alert alert-danger">
              <div *ngIf="nomEtPrenom.errors.required">Veuillez renseigner vos nom et prénom</div>
              <div *ngIf="nomEtPrenom.errors.minlength">Votre nom doit faire au moins 2 caractères</div>
            </div>
          </div>

          <div class="form-group">
            <input id="email"  name="email" class="form-control" type="email" placeholder="Votre Email *" [(ngModel)]="message.email" required #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
              <div *ngIf="email.errors.required">Merci de saisir votre adresse email</div>
              <div *ngIf="email.errors.pattern">Adresse email invalide</div>
            </div>
          </div>

          <div class="form-group">
            <input id="telephone" name="telephone" class="form-control" type="tel" placeholder="Votre numéro de téléphone *" [(ngModel)]="message.telephone" #telephone="ngModel" required pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$">
            <div *ngIf="telephone.invalid && (telephone.dirty || telephone.touched)" class="alert alert-danger">
              <div *ngIf="telephone.errors.required">Veuillez saisir votre numéro de téléphone</div>
              <div *ngIf="telephone.errors.pattern">Numéro de téléphone invalide</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <textarea id="message" name="message" class="form-control" placeholder="Votre Message *" [(ngModel)]="message.message" required #message="ngModel" minlength="50"></textarea>
            <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger">
              <div *ngIf="message.errors.required">Veuillez saisir votre message</div>
              <div *ngIf="message.errors.minlength">Votre message doit faire au moins 50 caractères</div>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>


      <!-- Add Google Recaptcha -->
      <p-captcha #reCaptcha class="myRecaptcha" siteKey="6LcDGCcaAAAAAO4qSh9-OUn7DZP0bbMY11nG06Bx" (onResponse)="showRecaptchaResponse($event)" (onExpire)="disableSubmitButton()"></p-captcha>


      <div class="text-center">
        <button pRipple id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit" [disabled]="!sendEmailForm.form.valid || !isRecaptchaValid">Envoyer</button>
      </div>
    </form>
    <!-- End of Contact Form -->
  </div>


  <div>
    <div class="BackgroundColorLINO addMarginTop">Venez nous rendre visite au siège</div>

    <div class="container-fluid lead text-center text-muted addMargin addPadding">
      <p>Horaires d'ouverture : Du lundi au Vendredi de 9h00 à 12h00 et de 13h30 à 17h30.</p>
    </div>

    <!-- GoogleMap -->
    <div class="background-color-grey map" #googleMap></div>
  </div>


  <!-- Start of Client component -->
  <app-client></app-client>
  <!-- Start of Client component -->


  <!-- Modal when RECAPTCHA is not validated -->
  <p-dialog [(visible)]="showRecaptchaModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p-header>Envoi de mail</p-header>
    <hr/>
    <p>Veuillez d'abord valider le recaptcha pour pouvoir envoyer le mail.</p>
    <img class="img-fluid myRecaptchaIMG" src="/assets/images/recaptcha.png" alt="recaptcha" loading="lazy"/>
    <hr/>
    <p-footer>
      <button pRipple type="button" class="btn btn-default btn-primary" (click)="showRecaptchaModal=false">Fermer</button>
    </p-footer>
  </p-dialog>


  <!-- Modal when email is sent successfully -->
  <p-dialog [(visible)]="showEmailSuccessModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p-header>Mail envoyé</p-header>
    <hr/>
    <p>Votre message a été envoyé avec succès.</p>
    <p>Nous vous contacterons dans les plus brefs délais.</p>
    <hr/>
    <p-footer>
      <button pRipple type="button" class="btn btn-default btn-primary" (click)="showEmailSuccessModal=false">Fermer</button>
    </p-footer>
  </p-dialog>


  <!-- Modal when email is not sent -->
  <p-dialog [(visible)]="showEmailEchecModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p-header>Une erreur est survenue</p-header>
    <hr/>
    <p>Une erreur a empêché l'envoie de votre message.</p>
    <p>Veuillez nous excusez de la gêne ocasionnée.</p>
    <p>Nous vous prions de réessayer ultérieurement.</p>
    <hr/>
    <p-footer>
      <button pRipple type="button" class="btn btn-default btn-primary" (click)="showEmailEchecModal=false">Fermer</button>
    </p-footer>
  </p-dialog>

</section>
