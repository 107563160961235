import {Component, ElementRef, Renderer2} from '@angular/core';
import {Event as NavigationEvent, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from "rxjs/operators";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  // Fields/Properties
  public navigationIndex = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.enableScrollOnTopWhenNavigationChangeOrPageRefresh(router);
    this.setNavigationTabActiveOnPageRefresh(router);
  }

  /**
   *  Scroll page on top when natigation is changed (by clicking on the navigation tab) or when the page is refreshed.
   *
   * @param router
   */
  private enableScrollOnTopWhenNavigationChangeOrPageRefresh(router: Router): void {
    router.events.subscribe((evt: any) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;

        // scroll back to top
        window.scrollTo(0, 0);
      }
    });
  }

  /**
   * Activate the navigation tab that should be activated when page reloads.
   *
   * @param router
   */
  private setNavigationTabActiveOnPageRefresh(router: Router): void {
    router.events
      .pipe(
        // The "events" stream contains all the navigation events but here we only care about the NavigationStart event
        // as it contains information about what initiated the navigation sequence.
        filter(
          ( event: NavigationEvent ) => {
            return( event instanceof NavigationStart );
          }
        )
      )
      .subscribe(
        (event: NavigationStart) => {
          let route: string = event.url;

          if (route === "/accueil") {
            this.onNavClick(0);
          } else if (route === "/services") {
            this.onNavClick(1);
          } else if (route === "/portfolio") {
            this.onNavClick(2);
          } else if (route === "/contact") {
            this.onNavClick(3);
          }
        }
      )
    ;
  }

  /**
   * Activate the current navitagion tab.
   *
   * @param index
   */
  public onNavClick(index){
    this.navigationIndex = index;
  }

}
