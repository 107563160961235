import { Component, OnInit } from '@angular/core';

// Declare JQuery
declare var jQuery: any;



@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    this.initializeTagCanvas();
  }

  /**
  * Initialise le TagCanvas
  */
  private initializeTagCanvas(): void {
    jQuery(document).ready(function(){
      if(!jQuery('#myTagCanvas').tagcanvas({
//    	textFont: 'Impact,Arial Black,sans-serif',
        textFont: null,
        textColour: null,
        outlineThickness : 0,
        outlineColour: '#FFFFFF',
        shape: 'sphere',
        textHeight: 23,
        weight : true,
        weightFrom: null,
        weightMode: 'size',
        reverse: true,
        shadow: '#000000',
        shadowBlur: 1,
        interval: 35,
        maxSpeed: 0.05,
        minBrightness: 0.1,
        depth: 0.92,
        pulsateTo: 0.2,
        pulsateTime: 0.75,
        initial: [0.1, -0.1],
        decel: 0.95,
        hideTags: false
      })) { // TagCanvas failed to load
        jQuery('#myTagCanvasContainer').hide();
     }
    });
  }

}
