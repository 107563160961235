<section id="client" class="container">

  <div class="row container-fluid">

    <!-- Start Page Title -->
    <div class="page-title">
      <h1 class="text-left">Nos Clients</h1>
      <div class="bolded-line"></div>
    </div>
    <!-- End Page Title -->


    <!-- Start client logo slider -->
    <div>
      <ul id="slider">
        <li *ngFor="let partnerLogo of partnerLogos">
          <img class="img-responsive"src="{{partnerLogo.url}}" alt="{{partnerLogo.description}}"/>
        </li>
      </ul>
    </div>
    <!-- End client logo slider -->

  </div>

</section>
