/// <reference types="@types/googlemaps" />

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';

import {NgForm} from '@angular/forms';

// My services
import {SendEmailService} from '../send-email.service';

// classe servant de model pour l'envoi de mail
import {Message} from '../message';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public message: Message;
  public isRecaptchaValid : boolean = false;
  public showRecaptchaModal: boolean = false;
  public showEmailSuccessModal: boolean = false;
  public showEmailEchecModal: boolean = false;

  @ViewChild('sendEmailForm', {static: true})
  templateForm: NgForm;

  @ViewChild('reCaptcha')
  reCaptcha;

  @ViewChild('googleMap', {static: true})
  gmapElement: any;
  map: google.maps.Map;

  constructor(private sendEmailService: SendEmailService) {

    this.message = new Message();
  }

  /**
   * Initialisation du composant
   */
  ngOnInit() {
    // On initialise la carte Google Map
    this.initializeMap();
  }

  /**
   * Méthode qui initialise la carte Google Map
   */
  private initializeMap(): void {
    // Pour le marker
    let latitude: number = 12.652033;
    let longitude: number = -8.0259767;
    let textDuMarker: string = "<strong>Imprimerie Nouvelle LINO</strong> <br/> " //
      + "<strong>Adresse :</strong> Rue 495, Porte 235 - Badialan III, Bamako (MALI) <br/> " //
      + "<br/> " //
      + "Notre siège";
    let mapProperties = {
      zoom: 14,
      center: new google.maps.LatLng(12.652033, -8.0259767),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false
    };
    // Création de la carte Google Map
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);

    // On crée un marker cliquable sur la carte Google Map
    let myPosition = new google.maps.LatLng(latitude, longitude);
    let marker = new google.maps.Marker({position: myPosition, map: this.map});
    let i;
    google.maps.event.addListener(marker, 'click', (function (marker, i) {
      return function () {
        let infowindow = new google.maps.InfoWindow();
        infowindow.setContent(textDuMarker);
        infowindow.open(this.map, marker);
      }
    })(marker, i));
  }

  /**
   * Méthode pour l'envoie de mail
   */
  public sendEmail(message: Message): void {
    this.sendEmailService.sendEmail(message)
      .subscribe(
        response => {
          let responseStatus = JSON.stringify(response.responseStatus).trim().valueOf();

          if (response && responseStatus === 'true') {
            // Mail envoyé avec succès => on réinitialise le formulaire
            this.templateForm.resetForm();

            // on ouvre le modal qui indique que le mail a été envoyé avec succès
            this.showEmailSuccessModal = true;
          } else {
            // Echec d'envoi du mail =>  on ouvre le modal qui indique que le mail n'a pas pu être envoyé
            this.showEmailEchecModal = true;

            // on réinitialise le captcha
            this.reCaptcha.reset();
          }
        },
        error => {
          // Erreur d'envoi du mail => on ouvre le modal qui indique que le mail n'a pas pu être envoyé
          this.showEmailEchecModal = true;

          // on réinitialise le captcha
          this.reCaptcha.reset();

          // on réinitialise l'objet "Message"
          this.message = new Message();
        }
      )
  }

  /**
   * Méthode pour vérifier le résultat du captcha
   *
   * @param recaptchaResult
   */
  public showRecaptchaResponse(recaptchaResult): void {
    let recaptchaResponse = JSON.stringify(recaptchaResult.response).trim().valueOf();

    if (recaptchaResponse === "" || recaptchaResponse.length == 0 ) {
      // captcha is invalid
      // on laisse le bouton "disabled" et on active le modal du recaptcha à valider
      this.isRecaptchaValid = false;
      this.showRecaptchaModal = true;
    } else {
      // captcha is valid
      this.isRecaptchaValid = true;
      this.showRecaptchaModal = false;
    }
  }

  public disableSubmitButton(): void {
    this.isRecaptchaValid = true;

    // on réinitialise le captcha
    this.reCaptcha.reset();
  }

}
