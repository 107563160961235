<section id="services">

  <div class="container">
    <!-- Start Page Title -->
    <div class="page-title">
      <h1 class="text-left">Services</h1>
      <div class="bolded-line"></div>
    </div>
    <!-- End Page Title -->

    <div class="BackgroundColorLINO">Nos Services</div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="lead text-justify text-muted addMargin">
          <p>Pour toutes vos conceptions, réalisations et productions en infographie, en art graphique, flashage, impression numérique, offset, typo, relief, reliure simple et en spirale, dos carré collé, vous pouvez compter sur Imprimerie Nouvelle LINO.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="lead text-justify text-muted addMargin">
          <p>
            Dès votre rentrée, notre équipe de service à la clientèle courtoise vous reçoit et prépare rapidement un devis d'impression à partir de l'information que vous avez fournie. Une fois que vous approuvez le devis, nous allons recueillir, photos, images et textes. Votre dossier est ensuite transféré au service graphique.
            <br/>
            La dernière étape est la mise en forme et la livraison.
          </p>
        </div>
      </div>
    </div>
  </div>


  <div class="background-color-grey">
    <div class="container">
      <div class="BackgroundColorLINO">Notre promesse</div>

      <div id="myTagCanvasContainer" class="addMargin">
        <canvas id="myTagCanvas" class="myCanvas">
          <ul>
            <li><a href="javascript:void(0);" style="color:#5ec6ff;font-size:32px;"><span>Qualité</span></a></li>
            <li><a href="javascript:void(0);" style="color:#5e90ff;font-size:32px;"><span>Rapidité</span></a></li>
            <li><a href="javascript:void(0);" style="color:#5ec6ff;font-size:32px;"><span>Sérieux</span></a></li>
            <li><a href="javascript:void(0);" style="color:#5e90ff;font-size:32px;"><span>Bon prix</span></a></li>
            <li><a href="javascript:void(0);" style="color:#5ec6ff;font-size:32px;"><span>Livraison à temps</span></a></li>
            <li><a href="javascript:void(0);" style="color:#5e90ff;font-size:32px;"><span>Satisfaction</span></a></li>
          </ul>
        </canvas>
      </div>

      <div class="addPadding">
        <h3 class="text-center text-muted">Nous dépassons toujours vos attentes</h3>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="row addMargin">
      <div class="col-lg-3 col-md-6 addPadding">
        <div class="service-box mt-5 mx-auto">
          <i class="fa fa-4x fa-building text-primary mb-3 sr-icons"></i>
          <h3 class="mb-3">Imprimerie Commerciale</h3>
          <p class="lead text-center text-muted">Presses, affiches, pochettes, brochures, magazines, calendriers, cartes de visite, livres spécialisés, manuels, agendas, répertoires, dépliants, catalogues, papeteries, programmes, noir et blanc, couleurs multiples, quadrichromie.</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 addPadding">
        <div class="service-box mt-5 mx-auto">
          <i class="fa fa-4x fa-briefcase text-primary mb-3 sr-icons"></i>
          <h3 class="mb-3">Imprimés d’affaires</h3>
          <p class="lead text-center text-muted">Court, moyen et long tirages, copies simples et multiples, livrets, tablettes, papier laser, étiquettes, autocollantes, formulaires d&rsquo;hôpitaux, pochettes collées, documents sécuritaires (chèques, certificats, diplômes).</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 addPadding">
        <div class="service-box mt-5 mx-auto">
          <i class="fa fa-4x fa-print text-primary mb-3 sr-icons"></i>
          <h3 class="mb-3">Pré-impression</h3>
          <p class="lead text-center text-muted">Composition / montage, sortie de films sur imageuse, épreuves couleur, infographie, environnements MAC et PC.</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 addPadding">
        <div class="service-box mt-5 mx-auto">
          <i class="fa fa-4x fa-book text-primary mb-3 sr-icons"></i>
          <h3 class="mb-3">Reliure et finition</h3>
          <p class="lead text-center text-muted">Découpe, pliages variés et multiples, numérotage mécanique, assemblage, brochage, plastification, perforation, reliure spirale.</p>
        </div>
      </div>
    </div>
  </div>


  <!-- Start of Client component -->
  <app-client></app-client>
  <!-- Start of Client component -->


</section>
