import {Component, OnInit} from '@angular/core';

import partnerLogosForAcceuilComponent from "../../assets/json/partnerLogosForAcceuilComponent.json";


@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  // Fields/Properties
  public partnerLogosByRow : any[];

  constructor() { }

  ngOnInit() {
    // load the partner logos JSON file
    this.partnerLogosByRow = partnerLogosForAcceuilComponent;
  }

}
