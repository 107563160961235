<section id="accueil">

  <!-- Start of JumboTron -->
  <div class="jumbotron img-responsive">
    <div class="container">
      <h1 class="big-text-white">LINO Imprimerie Nouvelle</h1>
      <h1 class="big-text-white">Yamoussa COULIBALY & Fils</h1>
    </div>
  </div>
  <!-- End of JumboTron -->


  <div class="container-fluid addMargin addPadding">
    <h1 class="BackgroundColorLINO">Qui sommes-nous ?</h1>

    <p class="lead text-justify text-muted">
      Nous sommes LINO Imprimerie Nouvelle SARL créée en <strong>juillet 1977</strong>, inscrit au Registre de commerce à Bamako.
      Notre imprimerie est bien équipée et dispose de matériels suffisants pour faire une grande variété d’imprimée
      dont ses clients qu’ils soient privés, semi-privés ou étatiques peuvent avoir besoin.
    </p>
  </div>


  <div pDefer>
    <ng-template>
      <!-- Image de fond pour effet parallax -->
      <div id="bgimg-2" class="bgimg-2"></div>
    </ng-template>
  </div>


  <div class="container-fluid addMargin addPadding" style="position:relative;">
    <h1 class="BackgroundColorLINO">Qu'est-ce qu'on fait ?</h1>

    <div class="lead text-justify text-muted">
      <p>Ces imprimés sont des cartes de toutes sortes <em>(visite, mariage, baptême, carte de membre d’associations, fiches cartonnées recto simple et recto-verso)</em>, des carnets en plusieurs feuillets sur papier ordinaire ou autocopiant d’une seule impression à plusieurs avec mentions sécuritaires.</p>
      <p>Nous somme aussi spécialisés dans l’impression de presse : <em>journaux, magazines, revues et bulletins d’informations, livres, etc...</em> Nous avons de l’expérience dans la confection des chéquiers de beaucoup de banques de la place, lettres chèques etc...</p>
      <p>LINO Imprimerie est l’imprimeur concepteur de la première version et de la deuxième version de la carte brune CEDEAO.</p>
    </div>
  </div>


  <div pDefer>
    <ng-template>
      <!-- Image de fond pour effet parallax -->
      <div id="bgimg-3" class="bgimg-3">
        <h2 class="big-text-white text-center">Pour tous vos travaux d’impression, faites confiance à LINO Imprimerie Nouvelle.</h2>
      </div>
    </ng-template>
  </div>


  <div class="container-fluid addMargin addPadding" style="position:relative;">
    <h1 class="BackgroundColorLINO">Quelques uns de nos clients</h1>

    <div class="row" *ngFor="let partnerLogoByRow of partnerLogosByRow">
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2" *ngFor="let logo of partnerLogoByRow.partnerLogos">
          <div pDefer>
            <ng-template>
              <div class="thumbnail">
                <img class="img-responsive" src="{{logo.url}}" alt="{{logo.description}}" loading="lazy">
              </div>
            </ng-template>
          </div>
        </div>
    </div>
 </div>


  <div class="addMarginTop addMarginBottom">
    <div class="container-fluid addMargin addPadding" style="position:relative;">
      <h1 class="BackgroundColorLINO">Vous avez un projet d'impression ?</h1>

      <a pRipple routerLink="/contact"><button type="button" class="btn btn-primary">Contactez-nous !</button></a>
    </div>
  </div>

</section>
