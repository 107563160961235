import {Component, OnInit} from '@angular/core';

import partnerLogosForClientComponent from "../../assets/json/partnerLogosForClientComponent.json";

// Declare JQuery
declare var jQuery: any;


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  // Fields/Properties
  public partnerLogos : any[];

  constructor() { }

  ngOnInit() {
    // load the partner logos JSON file
    this.partnerLogos = partnerLogosForClientComponent;

    this.initializeClientSlider();
  }

  /**
  *
  */
  private initializeClientSlider(): void {
    jQuery(document).ready(function(){
      jQuery('#slider').bxSlider({
        randomStart: false,
        mode: 'horizontal',
        auto: true,
        speed: 300,
        pause: 5000,
        responsive: true,
        infiniteLoop: true,
        useCSS: true,
        touchEnabled: true,
        pager: false,
        controls: false,
        slideMargin: 30,
        slideWidth: 250,
        minSlides: 1,
        maxSlides: 6,
        moveSlides: 5,
        shrinkItems : true
  		});
    });
  }

}
