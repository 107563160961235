<!-- Footer -->
<footer id="footer" class="container-fluid">

  <div class="container">
    <div class="row">
      <div class="col-xs-6 col-md-3 column">
        <h3 class="footer-title d-none d-sm-none d-md-block">LINO</h3> <!-- Hidden on xs & sm-->
        <div pDefer>
          <ng-template>
            <img class="img-fluid" src="/assets/images/logo-lino-01.jpg" alt="Logo LINO" loading="lazy"/>
          </ng-template>
        </div>
      </div>

      <div class="col-xs-6 col-md-3 column" (click)="showOrHide('2')">
        <h3 class="footer-title">Services</h3>
        <ul [@visibilityChanged]="isVisible2">
          <li><a href="#">Pré-impression</a></li>
          <li><a href="#">Imprimerie commerciale</a></li>
          <li><a href="#">Imprimés d'affaires</a></li>
          <li><a href="#">Reliure et finition</a></li>
        </ul>
      </div>

      <div class="col-xs-6 col-md-3 column" (click)="showOrHide('3')">
        <h3 class="footer-title">Contact</h3>
        <ul [@visibilityChanged]="isVisible3">
          <li>
            <i class="fa fa-home"></i> Adresse Postale <br/>
            <!--
            <a href="geo:12.6520239,-8.0938285"></a>
            <a href="geo:@12.6520239,-8.0938285" title="Venir à notre rencontre"></a>
            -->
            <a href="https://www.google.com/maps/search/?api=1&query=12.6520239,-8.0938285&query_place_id=ChIJO4kWO-3MUQ4RP2oFPOuJHYk" target="_blank" rel="noopener" title="Venir à notre rencontre">
              <span>Rue: 495, Porte: 235</span>
              <br/>
              <span>Badialan III, Bamako (MALI)</span>
              <br/>
              <span>BP: 600</span>
            </a>
          </li>
          <li>
            <i class="fa fa-envelope"></i>
            <a href="mailto:info@imprimerielino.com" target="_blank" rel="noopener" title="Nous contacter par mail info@imprimerielino.com"><span> info@imprimerielino.com</span></a>
          </li>
          <li>
            <i class="fa fa-phone"></i>
            <a href="tel:0022320224901" target="_blank" rel="noopener" title="Nous contacter par téléphone (+223) 20.22.49.01"><span> Tél: (+223) 20.22.49.01</span></a>
          </li>
          <li>
            <i class="fa fa-fax"></i>
            <a href="tel:0022320224901" target="_blank" rel="noopener" title="Nous contacter par fax (+223) 20.22.71.81"><span> Fax: (+223) 20.22.71.81</span></a>
          </li>
          <div>
          <li>
            <!-- Facebook -->
            <a href="https://www.facebook.com/lino.imprimerie" title="" target="_blank" rel="noopener"><i class="fa fa-facebook-square fa-2x white-text" aria-hidden="true"> </i></a>
            <!-- Linkedin -->
            <a href="https://www.linkedin.com/in/lino-imprimerie-nouvelle-6bbaa6207" title="" target="_blank" rel="noopener"><i class="fa fa-linkedin-square fa-2x white-text" aria-hidden="true"> </i></a>
          </li>
          </div>
        </ul>
      </div>

      <div class="col-xs-6 col-md-3 column" (click)="showOrHide('4')">
        <h3 class="footer-title">Site Web</h3>
        <ul [@visibilityChanged]="isVisible4">
          <li><a pRipple href="javascript:void(0)" (click)="showWebsitePlanModal=true">Plan du Site</a></li>
          <!-- <li><a href="#">Mentions Légales</a></li> -->
          <!-- <li><a href="#">Langues</a></li> -->
          <br class="hidden-md"/>
        </ul>
      </div>
    </div>
  </div>



  <!-- Start HR + Copyright -->
  <div class="clearfix w-100 white-hr"><hr></div>

  <div id="footerCopyright" class="container">
    <span class="attribution"><small>Copyright &copy; 1977 - {{ today | date: 'yyyy' }} | LINO Imprimerie Nouvelle. Tous droits réservés.</small></span>
  </div>
  <!-- End HR + Copyright -->



  <!-- Start Scroll to Top Button -->
  <ul class="scroll-to-top hidden-xs" title="Top" (click)="scrollToTop()">
    <li>
      <i pRipple  class="fa fa-arrow-circle-up fa-3x"></i>
    </li>
  </ul>
  <!-- End Scroll to Top Button -->



  <!-- Modal for Website plan -->
  <p-dialog [(visible)]="showWebsitePlanModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <p-header>Plan du site web</p-header>
    <hr/>
    <p>Accueil</p>
    <p>Services</p>
    <p>Portfolio</p>
    <p>Contact</p>
    <hr/>
    <p-footer>
      <button pRipple type="button" class="btn btn-default btn-primary" (click)="showWebsitePlanModal=false">Fermer</button>
    </p-footer>
  </p-dialog>

</footer>

