// Angular modules
import { Injectable } from '@angular/core';

// Angular HTTP
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RxJs
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// classe servant de model pour l'envoi de mail
import { Message } from './message';

// Angular HTTP default options
const httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'}) };



// Interface servant à mapper le type de retour du HTTP POST
export interface IMessageResponse {
  responseStatus: string;
  responseJsonError: string;
}



@Injectable()
export class SendEmailService {

  // URL vers le fichier PHP qui gère l'envoie du mail
  private sendMailPhp = './assets/php/mailSender.php';

  constructor(private http: HttpClient) { }

  /**
  * Méthode du service qui envoie le mail
  */
  sendEmail(message: Message): Observable<IMessageResponse> {
    return this.http.post<IMessageResponse>(this.sendMailPhp, message, httpOptions)
      .pipe(
        tap((message: IMessageResponse) => {
            console.log('response message : ' + JSON.stringify(message));
          }),
        catchError(this.handleError<IMessageResponse>('sendMail'))
      );
  }

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
