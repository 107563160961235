import {Component, OnInit} from '@angular/core';

import portfolioComponentImages from "../../assets/json/portfolioComponentImages.json";

// Declare JQuery
declare var jQuery: any;



@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  // Fields/Properties
  public filterIndex = 0;
  public portfolioImages : any[];

  constructor() { }

  ngOnInit() {
    // load portfolio images JSON file
    this.portfolioImages = portfolioComponentImages;

    this.initializeFilter();
  }

  /**
  * Initialise le filtre pour les images du portfolio
  */
  private initializeFilter(): void {
    jQuery(document).ready(function(){
      jQuery(".filter-button").click(function(){
        var value = jQuery(this).attr('data-filter');

        if(value == "all") {
          //jQuery('.filter').removeClass('hidden');
          jQuery('.filter').show('1000');
        } else {
//          jQuery('.filter[filter-item="'+value+'"]').removeClass('hidden');
//          jQuery(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
            jQuery(".filter").not('.'+value).hide('3000');
            jQuery('.filter').filter('.'+value).show('3000');
        }
      });
    });
  }

  /**
   * Activate the current filter button.
   *
   * @param index
   */
  public onFilterClick(index){
    this.filterIndex = index;
  }

}
