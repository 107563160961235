import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    NgbModule
  ],
  exports: [BsDropdownModule, NgbModule],
  declarations: []
})
export class AppBootstrapModule { }
