<header>
  <!-- Start of Contact Details and Social Icons -->
  <section id="header-contact-details" class="sticky-top">
    <!--Grid row-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <ul class="header-top-liner">
            <li class="float-left d-none d-sm-block"> <!-- Hidden on XS only -->
              <i class="fa fa-envelope"></i>
              <a href="mailto:info@imprimerielino.com" title="Nous contacter par mail à : info@imprimerielino.com"><strong> info@imprimerielino.com | </strong></a>
            </li>

            <li class="float-left">
              <i class="fa fa-phone"></i>
              <a href="tel:0022320224901" title="Nous contacter par téléphone au : +(223)20.22.49.01"><strong> +(223) 20.22.49.01</strong></a>
            </li>

            <!--Facebook-->
            <li class="float-right">
              <a href="https://www.facebook.com/lino.imprimerie" title="" target="_blank" rel="noopener"><i class="fa fa-facebook fa-lg white-text" aria-hidden="true"> </i></a>
            </li>

            <!--Linkedin-->
            <li class="float-right">
              <a href="https://www.linkedin.com/in/lino-imprimerie-nouvelle-6bbaa6207" title="" target="_blank" rel="noopener"><i class="fa fa-linkedin fa-lg white-text" aria-hidden="true"> </i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--Grid row-->
  </section>
  <!-- End of Contact Details and Social Icons -->
</header>


<!-- Start of Navbar -->
<section class="sticky-top">
  <div>
    <nav id="navbar" class="navbar navbar-expand-lg sticky-top col-md-12 myCustomNavbar">
      <a class="navbar-brand mr-auto mt-2 mt-lg-0" routerLink="/accueil">
        <p class="d-none d-md-block"> <!-- hide on screens smaller than md -->
          <img src="/assets/images/logo-lino-02.jpg" alt="LINO Imprimerie Nouvelle" loading="lazy"/>
          Imprimerie Nouvelle
        </p>
        <p class="d-md-none"> <!-- hide on screens wider than md -->
          <img class="img-fluid" src="/assets/images/logo-lino-02.jpg" alt="LINO Imprimerie Nouvelle" loading="lazy"/>
        </p>
      </a>

      <!-- Toggler/collapsible Button -->
      <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarChild" aria-controls="navbarChild" aria-expanded="false" aria-label="Toggle navigation">
        Menu <i class="fa fa-bars"></i>
<!--        <span class="navbar-toggler-icon"></span>-->
      </button>

      <div id="navbarChild" class="collapse navbar-collapse myCustomNavbarItems">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item" [ngClass]="{'active': navigationIndex === 0}" (click)="onNavClick(0)"><a pRipple class="nav-link" role="button" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/accueil">Accueil</a></li>
          <li class="nav-item" [ngClass]="{'active': navigationIndex === 1}" (click)="onNavClick(1)"><a pRipple class="nav-link" role="button" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/services">Services</a></li>
          <li class="nav-item" [ngClass]="{'active': navigationIndex === 2}" (click)="onNavClick(2)"><a pRipple class="nav-link" role="button" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/portfolio">Portfolio</a></li>
          <li class="nav-item" [ngClass]="{'active': navigationIndex === 3}" (click)="onNavClick(3)"><a pRipple class="nav-link" role="button" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/contact">Contact</a></li>
        </ul>
      </div>
    </nav>
  </div>
  <!-- End of Navbar -->
</section>
